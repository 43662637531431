@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?5vzsga");
  src: url("../fonts/icomoon.eot?5vzsga#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?5vzsga") format("truetype"), url("../fonts/icomoon.woff?5vzsga") format("woff"), url("../fonts/icomoon.svg?5vzsga#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-line:before {
  content: "\e900";
}

.icon-facebook:before {
  content: "\ea90";
}

.icon-twitter:before {
  content: "\ea96";
}

.glyphicon-tag {
  color: #aaa !important;
}

.glyphicon-tag:before {
  color: #aaa !important;
}

/**
 * Reset
 */
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

li,
nav,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  border: 0;
  border-top: 1px solid #ccc;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

/**
 * body
 */
body {
  width: 100%;
  color: #222222;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7;
  vertical-align: bottom;
  background: #FFFFFF;
}

/**
 * link
 */
a {
  color: #222222;
  text-decoration: none;
}

a:hover {
  color: #222222;
  text-decoration: underline;
}

/**
 * clearfix
 */
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  /*overflow: hidden;*/
}

.row {
  margin-right: 0px;
  margin-left: 0px;
  zoom: 1;
}

.row:after {
  display: block;
  content: "";
  clear: both;
}

.pc-width {
  margin: 0 auto;
  width: 980px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

header .header_bg {
  padding: 25px 0 22px;
  background: #fff;
}

header .row {
  display: flex;
  justify-content: center;
  align-items: center;
  zoom: 1;
}

header .row:after {
  display: block;
  content: "";
  clear: both;
}

header p {
  padding-left: 40px;
}

header h1.logo {
  text-align: center;
}

header .menu-trigger,
header .menu-trigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}

header .menu-trigger {
  position: relative;
  float: right;
  margin-right: 15px;
  width: 44px;
  height: 30px;
}

header .menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #949494;
}

header .menu-trigger span:nth-of-type(1) {
  top: 0;
}

header .menu-trigger span:nth-of-type(2) {
  top: 13px;
}

header .menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

header .menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(13px) rotate(-45deg);
  transform: translateY(13px) rotate(-45deg);
}

header .menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}

header .menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-13px) rotate(45deg);
  transform: translateY(-13px) rotate(45deg);
}

nav {
  display: none;
}

nav ul {
  width: 100%;
}

nav ul li {
  width: 100%;
}

nav ul li a {
  position: relative;
  display: block;
  text-align: center;
  font-size: 3.0rem;
  border-bottom: 1px solid #222;
  background: #fff;
}

nav ul li a:hover {
  text-decoration: none;
}

nav ul li a span {
  position: absolute !important;
  top: 50% !important;
  right: 10px;
  font-size: 2rem;
  margin-top: -10px;
}

main {
  margin-top: 82px;
  margin-bottom: 20px;
  padding-bottom: 25px;
  min-height: 324px;
  background: url("/images/main.jpg") 50% -82px no-repeat;
  background-size: 100% auto;
}

main h2 {
  padding: 34px 0 50px;
  text-align: center;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  font-size: 3.2rem;
}

@media screen and (max-width: 979px) {
  main {
    background-size: 980px auto;
  }
}

@media only screen and (min-width: 1921px) {
  main {
    background-size: 1920px auto;
  }
}

#sideWrap {
  width: 100%;
}

#side {
  width: 100%;
}

#side.fixed {
  width: 307px;
}

.tp-Main {
  margin: 0 auto;
  padding: 15px 20px 12px;
  width: 870px;
  background: #fff;
}

.tp-Main h3 {
  padding-bottom: 15px;
  font-size: 1.4rem;
  font-weight: 700;
}

.tp-Main_Inner {
  zoom: 1;
}

.tp-Main_Inner:after {
  display: block;
  content: "";
  clear: both;
}

.tp-Main_Inner-List {
  margin-bottom: 12px;
  float: left;
}

.tp-Main_Inner-List:nth-child(odd) {
  width: 55%;
}

.tp-Main_Inner-List h4 {
  padding-bottom: 6px;
  font-size: 1.3rem;
  font-weight: 700;
}

.tp-Main_Inner-List ul {
  margin-left: auto;
  display: flex;
  flex-wrap: nowrap;
}

.tp-Main_Inner-List ul li {
  color: #21a3ff;
  font-size: 1.3rem;
}

.tp-Main_Inner-List ul li a {
  color: #21a3ff;
  font-size: 1.3rem;
}

.tp-Lineup {
  margin-bottom: 40px;
  min-width: 980px;
}

.tp-Lineup .col-xs-4 {
  margin: 0;
  padding: 0;
  height: 326px;
}

.tp-Lineup .col-xs-4 a {
  position: relative;
  display: block;
  padding-top: 230px;
  width: 100%;
  height: 100%;
  background: url("/images/img01.jpg") center no-repeat;
  background-size: cover;
}

.tp-Lineup .col-xs-4 a:hover {
  opacity: 0.7;
}

.tp-Lineup .col-xs-4 a .list {
  position: absolute;
  bottom: 10px;
  padding: 0 24px;
  zoom: 1;
}

.tp-Lineup .col-xs-4 a .list:after {
  display: block;
  content: "";
  clear: both;
}

.tp-Lineup .col-xs-4 a .list h3 {
  color: #fff;
  font-weight: 700;
  font-size: 2.0rem;
  padding: 0 0 10px;
  line-height: 1.5;
  text-shadow: 2px 2px 3px #222222;
}

.tp-Lineup .col-xs-4 a .list p {
  float: left;
  margin-right: 20px;
  color: #fff;
  font-size: 1.2rem;
  text-shadow: 2px 2px 3px #222222;
}

.tp-Contents h2 {
  margin-bottom: 10px;
  padding-bottom: 20px;
  font-weight: 700;
  font-size: 1.7rem;
  border-bottom: 1px solid #222;
}

.tp-Contents_Attention {
  display: flex;
  flex-wrap: nowrap;
  padding: 20px 0;
  color: #222222;
  text-decoration: none;
  border-bottom: 1px solid #d9d9d9;
}

.tp-Contents_Attention:first-of-type {
  padding: 10px 0 20px;
}

.tp-Contents_Attention .img {
  position: relative;
  overflow: hidden;
  margin-right: 30px;
  width: 120px;
  height: 120px;
  border-radius: 4px;
}

.tp-Contents_Attention .img a:hover {
  opacity: 0.7;
}

.tp-Contents_Attention .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.tp-Contents_Attention .txt {
  width: 425px;
}

.tp-Contents_Attention .txt h3 {
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.4;
}

.tp-Contents_Attention .txt ul.tags {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.tp-Contents_Attention .txt ul.tags li {
  margin-right: 15px;
}

.tp-Contents_Attention .txt .data {
  zoom: 1;
}

.tp-Contents_Attention .txt .data:after {
  display: block;
  content: "";
  clear: both;
}

.tp-Contents_Attention .txt .data p {
  float: left;
  margin-right: 20px;
  color: #aaaaaa;
  font-size: 1.2rem;
}

.tp-Contents_Attention .txt .data p a {
  color: #aaaaaa;
  font-size: 1.2rem;
}

.tp-Contents_Pager {
  margin: 70px 0 140px;
}

.tp-Contents_Pager ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tp-Contents_Pager ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
}

.tp-Contents_Pager ul li a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid #d9d9d9;
}

.tp-Contents_Pager ul li a:hover {
  background-color: #000000;
  color: #fff;
  text-decoration: none;
}

.tp-Contents_Pager ul li.current {
  background-color: #000000;
  color: #fff;
}

.tp-Contents_Area {
  margin-bottom: 30px;
}

.tp-Contents_Area ul {
  display: flex;
  flex-wrap: nowrap;
  padding: 20px 0;
  border-bottom: 1px solid #d9d9d9;
}

.tp-Contents_Area ul:first-of-type {
  padding: 10px 0 20px;
}

.tp-Contents_Area ul li {
  width: 50%;
}

.tp-Contents_Area ul li a {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.tp-Contents_Area ul li a .img {
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  width: 45px;
  height: 45px;
  border-radius: 4px;
}

.tp-Contents_Area ul li a .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.tp-Contents_Area ul li a:hover .img {
  opacity: 0.7;
}

.tp-Contents_Tags {
  margin-bottom: 30px;
  padding: 12px 0 10px;
}

.tp-Contents_Tags ul {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
}

.tp-Contents_Tags ul li {
  margin-right: 12px;
  margin-bottom: 10px;
}

.tp-Contents_Rank {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 0 5px;
  color: #222222;
  text-decoration: none;
  border-bottom: 1px solid #d9d9d9;
}

.tp-Contents_Rank .img {
  position: relative;
  overflow: hidden;
  margin-right: 15px;
  width: 60px;
  height: 80px;
}

.tp-Contents_Rank .img a:hover {
  opacity: 0.7;
}

.tp-Contents_Rank .img a span.num {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 15px;
  padding: 2px 0;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1;
  background-color: #fff;
}

.tp-Contents_Rank .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.tp-Contents_Rank .txt {
  width: 240px;
}

.tp-Contents_Rank .txt p {
  margin-right: 20px;
  color: #aaaaaa;
  font-size: 1.2rem;
}

.tp-Contents_Rank .txt h4 {
  font-size: 1.4rem;
}

.tp-Contents_Rank .txt h5 {
  font-size: 1.1rem;
}

.tp-Contents_Rank .txt a {
  color: #222222;
}

.tp-Contents_Affiliate {
  margin: 40px 0;
}

.tp-Contents_Pickup {
  margin-bottom: 50px;
}

.tp-Contents_Pickup ul {
  display: flex;
  flex-wrap: wrap;
}

.tp-Contents_Pickup ul li {
  width: 100%;
}

.tp-Contents_Pickup ul li a {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #d9d9d9;
}

.tp-Contents_Pickup ul li a .img {
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  width: 64px;
  height: 64px;
}

.tp-Contents_Pickup ul li a .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.tp-Contents_Pickup ul li a:hover .img {
  opacity: 0.7;
}

.tp-Contents_About p {
  padding: 10px 0 20px;
  font-size: 1.4rem;
}

.tp-Contents_About ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  padding: 15px 0;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  color: #fff;
  border-radius: 6px;
  line-height: 1;
  vertical-align: middle;
}

.tp-Contents_About ul li a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.tp-Contents_About ul li a.facebook {
  background: #3b5998;
  border-bottom: #404c66 solid 3px;
}

.tp-Contents_About ul li a.twitter {
  background: #55acee;
  border-bottom: #5698cb solid 3px;
}

.tp-Contents_About ul li a.instagram {
  color: #555;
  background: #fafafa;
  border: #eaeaea solid 1px;
  border-bottom: #e5e5e5 solid 3px;
}

.tp-Contents_About ul li a.line {
  background: #1dcd00;
  border-bottom: solid 4px rgba(0, 0, 0, 0.25);
}

.tp-Contents_About ul li a span {
  padding-right: 8px;
  font-size: 1.8rem;
}

.tp-Contents_About ul li a span img {
  width: 15px;
}

.tp-Contents p.link {
  padding-top: 12px;
  text-align: right;
}

.tp-Contents p.link a {
  color: #999999;
  font-size: 1.2rem;
}

.tp-Artswall {
  padding: 25px 0 25px 0;
  background-color: #f2f2f2;
  border-top: 2px solid #e6e6e6;
  border-bottom: 2px solid #e6e6e6;
}

.tp-Artswall_Inner {
  display: flex;
  flex-wrap: nowrap;
}

.tp-Artswall_Inner .img {
  margin-right: 40px;
  padding-top: 10px;
  width: 340px;
}

.tp-Artswall_Inner .img img {
  width: 100%;
}

.tp-Artswall_Inner .txt {
  width: 530px;
}

.tp-Artswall_Inner .txt h3 {
  padding-bottom: 15px;
  font-size: 1.7rem;
  font-weight: 700;
}

.tp-Artswall_Inner .txt p {
  font-size: 1.4rem;
}

.tp-Artswall_Inner .txt .links {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.tp-Artswall_Inner .txt .links .app {
  width: 190px;
  text-align: center;
}

.tp-Artswall_Inner .txt .links .app h4 {
  font-size: 1.7rem;
}

.tp-Artswall_Inner .txt .links .app ul {
  margin-top: 20px;
  zoom: 1;
}

.tp-Artswall_Inner .txt .links .app ul:after {
  display: block;
  content: "";
  clear: both;
}

.tp-Artswall_Inner .txt .links .app ul li {
  float: left;
}

.tp-Artswall_Inner .txt .links .app ul li:last-of-type {
  float: right;
}

.tp-Artswall_Inner .txt .links .app ul li a {
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.tp-Artswall_Inner .txt .links .app ul li a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.tp-Artswall_Inner .txt .links .artswall a {
  font-size: 1.7rem;
}

footer .ft-Inner {
  padding: 35px 0 25px 0;
}

footer .ft-Inner .links {
  display: flex;
  flex-wrap: nowrap;
  color: #666666;
  font-size: 1.2rem;
}

footer .ft-Inner .links a {
  color: #666666;
}

footer .ft-Inner .links h3 {
  margin-bottom: 20px;
  color: #555555;
  font-size: 1.4rem;
  font-weight: 700;
}

footer .ft-Inner .links h4 {
  color: #555555;
  font-size: 1.3rem;
  font-weight: 700;
}

footer .ft-Inner .links .search {
  width: 445px;
  margin-bottom: 30px;
  margin-right: 80px;
}

footer .ft-Inner .links .search ul {
  margin-bottom: 12px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

footer .ft-Inner .links .about {
  width: 360px;
}

footer .ft-Inner .links .about .link {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

footer .ft-Inner .links .about .link ul {
  width: 50%;
}

footer .ft-Inner .links .about .link ul li {
  margin-bottom: 12px;
}

footer .ft-Inner .links .tags {
  width: 445px;
  margin-right: 80px;
}

footer .ft-Inner .links .tags .link ul {
  display: flex;
  flex-wrap: wrap;
}

footer .ft-Inner .links .tags .link ul li {
  margin: 0 12px 10px 0;
}

footer .ft-Inner .links .bnr {
  width: 356px;
  height: 134px;
  background: #666666;
}

footer .ft-Copyright {
  padding: 10px 0;
  border-top: 1px solid #e6e6e6;
  background: #f2f2f2;
}

footer .ft-Copyright .pc-width {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

footer .ft-Copyright .pc-width h3 {
  margin-right: 30px;
}

footer .ft-Copyright .pc-width p {
  font-size: 1.0rem;
  color: #bbbbbb;
}
