@charset 'utf-8';
// ==========================================================================
// fonts
// ==========================================================================
$font-size-small: 1.3rem;
$font-size-regular: 1.4rem;
$font-size-large: 1.6rem;
$font-size-l-large: 1.8rem;
$font-size-x-large: 2rem;
$font-size-xx-large: 2.2rem;
$font-size-xxx-large: 2.4rem;
$font-size-h2: $font-size-xxx-large;
$font-size-h3: $font-size-x-large;
$font-size-h4: $font-size-x-large;
// ==========================================================================
// colors
// ==========================================================================
$font-color-normal: #222222;
$font-color-link: #222222;
// ==========================================================================
// backgrounds
// ==========================================================================
$background-body: #FFFFFF;
// ==========================================================================
// breakpoints
// ==========================================================================
$breakpoint-pc: 980px;
