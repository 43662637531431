@charset "utf-8";
@import "variables";
@font-face {
	font-family: 'icomoon';
	src: url("../fonts/icomoon.eot?5vzsga");
	src: url("../fonts/icomoon.eot?5vzsga#iefix") format('embedded-opentype'), url("../fonts/icomoon.ttf?5vzsga") format('truetype'), url("../fonts/icomoon.woff?5vzsga") format('woff'), url("../fonts/icomoon.svg?5vzsga#icomoon") format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-line:before {
	content: "\e900";
}

.icon-facebook:before {
	content: "\ea90";
}

.icon-twitter:before {
	content: "\ea96";
}

.glyphicon-tag{
	color: #aaa !important;
	&:before{
		color: #aaa !important;
	}
}
/**
 * Reset
 */
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
	border: 0;
	margin: 0;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
}

html {
	font-size: 62.5%;
}

body {
	line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

li,
nav,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
	content: "";
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* change border colour to suit your needs */
hr {
	border: 0;
	border-top: 1px solid #ccc;
	display: block;
	height: 1px;
	margin: 1em 0;
	padding: 0;
}

input,
select {
	vertical-align: middle;
}

*,
*:after,
*:before {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}
/**
 * body
 */
body {
	width: 100%;
	color: $font-color-normal;
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 400;
	font-size: $font-size-regular;
	line-height: 1.7;
	vertical-align: bottom;
	background: $background-body;
}
/**
 * link
 */
a {
	color: $font-color-link;
	text-decoration: none;

	&:hover {
		color: $font-color-link;
		text-decoration: underline;
	}
}
/**
 * clearfix
 */
@mixin clearfix {
	zoom: 1;

	&:after {
		display: block;
		content: "";
		clear: both;
	}
}

.container-fluid{
	padding-right: 0px;
	padding-left: 0px;
	/*overflow: hidden;*/
}

.row{
	margin-right: 0px;
	margin-left: 0px;
	@include clearfix;
}

.pc-width {
	margin: 0 auto;
	width: $breakpoint-pc;
}

header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;

	.header_bg{
		padding: 25px 0 22px;
		background: #fff;
	}

	.row {
		display: flex;
		justify-content: center;
		align-items: center;
		@include clearfix;
	}

	p {
		padding-left: 40px;
	}

	h1 {
		&.logo {
			text-align: center;
		}
	}

	.menu-trigger,
	.menu-trigger span {
		display: inline-block;
		transition: all 0.4s;
		box-sizing: border-box;
	}

	.menu-trigger {
		position: relative;
		float: right;
		margin-right: 15px;
		width: 44px;
		height: 30px;
	}

	.menu-trigger span {
		position: absolute;
		left: 0;
		width: 100%;
		height: 4px;
		background-color: #949494;
	}

	.menu-trigger span:nth-of-type(1) {
		top: 0;
	}

	.menu-trigger span:nth-of-type(2) {
		top: 13px;
	}

	.menu-trigger span:nth-of-type(3) {
		bottom: 0;
	}

	.menu-trigger.active span:nth-of-type(1) {
		-webkit-transform: translateY(13px) rotate(-45deg);
		transform: translateY(13px) rotate(-45deg);
	}

	.menu-trigger.active span:nth-of-type(2) {
		opacity: 0;
	}

	.menu-trigger.active span:nth-of-type(3) {
		-webkit-transform: translateY(-13px) rotate(45deg);
		transform: translateY(-13px) rotate(45deg);
	}
}

nav{
	display: none;
	ul{
		width: 100%;
		li{
			width: 100%;
			a{
				position: relative;
				display: block;
				text-align: center;
				font-size: 3.0rem;
				border-bottom: 1px solid #222;
				background: #fff;
				&:hover{
					text-decoration: none;
				}
				span{
					position: absolute !important;
					top:50% !important;
					right: 10px;
					font-size: 2rem;
					margin-top: -10px;
				}
			}
		}
	}
}

main {
	margin-top: 82px;
	margin-bottom: 20px;
	padding-bottom: 25px;
	min-height: 324px;
	background: url("/images/main.jpg") 50% -82px no-repeat;
	background-size: 100% auto;

	h2 {
		padding: 34px 0 50px;
		text-align: center;
		font-weight: 700;
		color: #fff;
		line-height: 1;
		font-size: 3.2rem;
	}
}
@media screen and (max-width: 979px) {
	main {
		background-size: 980px auto;
	}
}
@media only screen and (min-width: 1921px) {
	main {
		background-size: 1920px auto;
	}
}

#sideWrap{
	width: 100%;
}

#side{
	width: 100%;
	&.fixed{
		width: 307px;
	}
}

.tp {
	&-Main {
		margin: 0 auto;
		padding: 15px 20px 12px;
		width: 870px;
		background: #fff;

		h3 {
			padding-bottom: 15px;
			font-size: $font-size-regular;
			font-weight: 700;
		}

		&_Inner {
			@include clearfix;

			&-List {
				margin-bottom: 12px;
				float: left;

				&:nth-child(odd) {
					width: 55%;
				}

				h4 {
					padding-bottom: 6px;
					font-size: $font-size-small;
					font-weight: 700;
				}

				ul {
					margin-left: auto;
					display: flex;
					flex-wrap: nowrap;

					li {
						color: #21a3ff;
						font-size: $font-size-small;

						a {
							color: #21a3ff;
							font-size: $font-size-small;
						}
					}
				}
			}
		}
	}

	&-Lineup {
		margin-bottom: 40px;
		min-width: 980px;

		.col-xs-4 {
			margin: 0;
			padding: 0;
			height: 326px;

			a {
				position: relative;
				display: block;
				padding-top: 230px;
				width: 100%;
				height: 100%;
				background: url("/images/img01.jpg") center no-repeat;
				background-size: cover;

				&:hover {
					opacity: 0.7;
				}

				.list {
					position: absolute;
					bottom: 10px;
					padding: 0 24px;
					@include clearfix;

					h3 {
						color: #fff;
						font-weight: 700;
						font-size: 2.0rem;
						padding: 0 0 10px;
						line-height: 1.5;
						text-shadow: 2px 2px 3px #222222;
					}

					p {
						float: left;
						margin-right: 20px;
						color: #fff;
						font-size: 1.2rem;
						text-shadow: 2px 2px 3px #222222;
					}
				}
			}
		}
	}

	&-Contents {
		h2 {
			margin-bottom: 10px;
			padding-bottom: 20px;
			font-weight: 700;
			font-size: 1.7rem;
			border-bottom: 1px solid #222;
		}

		&_Attention {
			display: flex;
			flex-wrap: nowrap;
			padding: 20px 0;
			color: $font-color-link;
			text-decoration: none;
			border-bottom: 1px solid #d9d9d9;

			&:first-of-type {
				padding: 10px 0 20px;
			}

			.img {
				position: relative;
				overflow: hidden;
				margin-right: 30px;
				width: 120px;
				height: 120px;
				border-radius: 4px;

				a {
					&:hover {
						opacity: 0.7;
					}
				}

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					width: auto;
					height: 100%;
				}
			}

			.txt {
				width: 425px;

				h3 {
					padding-bottom: 15px;
					font-weight: 700;
					font-size: 1.7rem;
					line-height: 1.4;
				}

				ul.tags {
					display: flex;
					flex-wrap: wrap;
					padding-bottom: 10px;

					li {
						margin-right: 15px;
					}
				}

				.data {
					@include clearfix;

					p {
						float: left;
						margin-right: 20px;
						color: #aaaaaa;
						font-size: 1.2rem;

						a {
							color: #aaaaaa;
							font-size: 1.2rem;
						}
					}
				}
			}
		}

		&_Pager {
			margin: 70px 0 140px;

			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;

				li {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					width: 48px;
					height: 48px;

					a {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						align-items: center;
						width: 48px;
						height: 48px;
						border: 1px solid #d9d9d9;

						&:hover {
							background-color: #000000;
							color: #fff;
							text-decoration: none;
						}
					}

					&.current {
						background-color: #000000;
						color: #fff;
					}
				}
			}
		}

		&_Area {
			margin-bottom: 30px;

			ul {
				display: flex;
				flex-wrap: nowrap;
				padding: 20px 0;
				border-bottom: 1px solid #d9d9d9;

				&:first-of-type {
					padding: 10px 0 20px;
				}

				li {
					width: 50%;

					a {
						display: flex;
						flex-wrap: nowrap;
						align-items: center;

						.img {
							position: relative;
							overflow: hidden;
							margin-right: 10px;
							width: 45px;
							height: 45px;
							border-radius: 4px;

							img {
								position: absolute;
								top: 50%;
								left: 50%;
								-webkit-transform: translate(-50%, -50%);
								-ms-transform: translate(-50%, -50%);
								transform: translate(-50%, -50%);
								width: auto;
								height: 100%;
							}
						}

						&:hover {
							.img {
								opacity: 0.7;
							}
						}
					}
				}
			}
		}

		&_Tags {
			margin-bottom: 30px;
			padding: 12px 0 10px;

			ul {
				display: flex;
				flex-wrap: wrap;
				padding-bottom: 10px;
				border-bottom: 1px solid #d9d9d9;

				li {
					margin-right: 12px;
					margin-bottom: 10px;
				}
			}
		}

		&_Rank {
			display: flex;
			flex-wrap: nowrap;
			padding: 10px 0 5px;
			color: $font-color-link;
			text-decoration: none;
			border-bottom: 1px solid #d9d9d9;

			.img {
				position: relative;
				overflow: hidden;
				margin-right: 15px;
				width: 60px;
				height: 80px;

				a {
					&:hover {
						opacity: 0.7;
					}

					span.num {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 15px;
						height: 15px;
						padding: 2px 0;
						text-align: center;
						font-size: 1.2rem;
						line-height: 1;
						background-color: #fff;
					}
				}

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					width: auto;
					height: 100%;
				}
			}

			.txt {
				width: 240px;

				p {
					margin-right: 20px;
					color: #aaaaaa;
					font-size: 1.2rem;
				}

				h4 {
					font-size: 1.4rem;
				}

				h5 {
					font-size: 1.1rem;
				}

				a {
					color: #222222;
				}
			}
		}

		&_Affiliate {
			margin: 40px 0;
		}

		&_Pickup {
			margin-bottom: 50px;

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					width: 100%;

					a {
						display: flex;
						flex-wrap: nowrap;
						align-items: center;
						width: 100%;
						padding: 10px 0;
						border-bottom: 1px solid #d9d9d9;

						.img {
							position: relative;
							overflow: hidden;
							margin-right: 10px;
							width: 64px;
							height: 64px;

							img {
								position: absolute;
								top: 50%;
								left: 50%;
								-webkit-transform: translate(-50%, -50%);
								-ms-transform: translate(-50%, -50%);
								transform: translate(-50%, -50%);
								width: auto;
								height: 100%;
							}
						}

						&:hover {
							.img {
								opacity: 0.7;
							}
						}
					}
				}
			}
		}

		&_About {
			p {
				padding: 10px 0 20px;
				font-size: 1.4rem;
			}

			ul {
				li {
					a {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 8px;
						padding: 15px 0;
						text-align: center;
						font-size: 1.3rem;
						font-weight: 700;
						color: #fff;
						border-radius: 6px;
						line-height: 1;
						vertical-align: middle;

						&:hover {
							opacity: 0.7;
							text-decoration: none;
						}

						&.facebook {
							background: #3b5998;
							border-bottom: #404c66 solid 3px;
						}

						&.twitter {
							background: #55acee;
							border-bottom: #5698cb solid 3px;
						}

						&.instagram {
							color: #555;
							background: #fafafa;
							border: #eaeaea solid 1px;
							border-bottom: #e5e5e5 solid 3px;
						}

						&.line {
							background: #1dcd00;
							border-bottom: solid 4px rgba(0,0,0,0.25);
						}

						span {
							padding-right: 8px;
							font-size: 1.8rem;

							img {
								width: 15px;
							}
						}
					}
				}
			}
		}

		p.link {
			padding-top: 12px;
			text-align: right;

			a {
				color: #999999;
				font-size: 1.2rem;
			}
		}
	}
	&-Artswall {
		padding: 25px 0 25px 0;
		background-color: #f2f2f2;
		border-top: 2px solid #e6e6e6;
		border-bottom: 2px solid #e6e6e6;
		&_Inner{
			display: flex;
			flex-wrap: nowrap;
			.img{
				margin-right: 40px;
				padding-top: 10px;
				width: 340px;
				img{
					width: 100%;
				}
			}
			.txt{
				width: 530px;
				h3{
					padding-bottom: 15px;
					font-size: 1.7rem;
					font-weight: 700;
				}
				p{
					font-size: 1.4rem;
				}
				.links{
					display: flex;
					flex-wrap: nowrap;
					justify-content: space-between;
					align-items: center;
					margin-top: 20px;
					.app{
						width: 190px;
						text-align: center;
						h4{
							font-size: 1.7rem;
						}
						ul{
							margin-top: 20px;
							@include clearfix;
							li{
								float: left;
								&:last-of-type{
									float: right;
								}
								a{
									padding: 8px;
									background-color: #ffffff;
									border: 1px solid #e6e6e6;
									border-radius: 4px;
									&:hover{
										opacity: 0.7;
										text-decoration: none;
									}
								}
							}
						}
					}
					.artswall{
						a{
							font-size: 1.7rem;
						}
					}
				}
			}
		}
	}
}
footer {
	.ft{
		&-Inner{
			padding: 35px 0 25px 0;
			.links{
				display: flex;
				flex-wrap: nowrap;
				color: #666666;
				font-size: 1.2rem;
				a {
					color: #666666;
				}
				h3{
					margin-bottom: 20px;
					color: #555555;
					font-size: 1.4rem;
					font-weight: 700;
				}
				h4{
					color: #555555;
					font-size: 1.3rem;
					font-weight: 700;
				}
				.search{
					width: 445px;
					margin-bottom: 30px;
					margin-right: 80px;
					ul {
						margin-bottom: 12px;
						display: flex;
						flex-wrap: nowrap;
						align-items: center;
					}
				}
				.about{
					width: 360px;
					.link{
						display: flex;
						flex-wrap: nowrap;
						justify-content: space-between;
						ul {
							width: 50%;
							li{
								margin-bottom: 12px;
							}
						}
					}
				}
				.tags{
					width: 445px;
					margin-right: 80px;
					.link{
						ul{
							display: flex;
							flex-wrap: wrap;
							li{
								margin: 0 12px 10px 0;
							}
						}
					}
				}
				.bnr{
					width: 356px;
					height: 134px;
					background: #666666;
				}
			}
		}
		&-Copyright{
			padding: 10px 0;
			border-top: 1px solid #e6e6e6;
			background: #f2f2f2;
			.pc-width{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				h3{
					margin-right: 30px;
				}
				p{
					font-size: 1.0rem;
					color: #bbbbbb;
				}
			}
		}
	}
}
